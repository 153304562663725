import { useContext } from 'react';

import { ResolvedContextData } from '@common/defaults';

import { NextContext } from './NextContext';

export const useContextData = (): ResolvedContextData => {
    const contextData = useContext(NextContext) as ResolvedContextData;
    return contextData;
};
