import JsCookies from 'js-cookie';

import { ContextData } from '@common/defaults/ContextData';

import { SupertokensStatus } from './SupertokensStatus';

export const updateStatus = (
    contextData: Partial<ContextData>,
    isClientSide: boolean,
    fromStatusCode?: number,
) => {
    if (!contextData.supertokens) return SupertokensStatus.UNKNOWN;

    if (isClientSide) {
        contextData.supertokens.sFrontToken = JsCookies.get('sFrontToken') || '';
    }

    // We must check if the sFrontToken is set instead of the sAccessToken until the following issue is resolved: https://github.com/supertokens/supertokens-node/issues/790
    if (!contextData.supertokens.sFrontToken) {
        contextData.supertokens.status = SupertokensStatus.NOT_LOGGED_IN;
    } else if (fromStatusCode === 401) {
        contextData.supertokens.status = SupertokensStatus.NEEDS_REFRESH;
    } else if (contextData.supertokens.status !== SupertokensStatus.NEEDS_REFRESH) {
        // Assume the user is logged in, api will tell us if he/she's not
        contextData.supertokens.status = SupertokensStatus.LOGGED_IN;
    }
    return contextData.supertokens.status;
};
