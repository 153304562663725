import { FC } from 'react';

const FALLBACK_FONT = 'Helvetica, Arial, sans-serif' as const;

export const FallbackStyleBlock: FC<{}> = () => (
    <style
        id="fontfaces-fallback"
        key="fontfaces-fallback"
        dangerouslySetInnerHTML={{
            __html: `
                html {
                    font-family: ${FALLBACK_FONT};
                }

                :root {
                    --font-family-lato: ${FALLBACK_FONT};
                    --font-family-lexia: ${FALLBACK_FONT};
                    --font-family-open-sans: ${FALLBACK_FONT};
                    --font-family-oswald: ${FALLBACK_FONT};
                    --font-family-poppins: ${FALLBACK_FONT};
                    --font-family-titillium: ${FALLBACK_FONT};
                    --font-family-yrsa: ${FALLBACK_FONT};
                }`,
        }}
    />
);
