import type { default as HttpAgent, HttpsAgent, HttpsOptions } from 'agentkeepalive';
import type { AxiosInstance, AxiosStatic } from 'axios';
// FIXME: Super confused why these types don't properly match
import type { AxiosInstance as ImportAxiosInstance } from 'axios' assert { 'resolution-mode': 'import' };
import type { AxiosCacheInstance } from 'axios-cache-interceptor';

import { logger } from '@common/logger';
import { Duration } from '@common/types';

type Client = AxiosCacheInstance | AxiosInstance;

let httpAgent: HttpAgent | undefined = undefined;
let httpsAgent: HttpsAgent | undefined = undefined;
let globalClient: Client | undefined = undefined;
let lock: boolean = false;
const awaitingClient: ((client: Client) => void)[] = [];

export const getRawAgents = (): { httpAgent: HttpAgent | undefined; httpsAgent: HttpsAgent | undefined } => ({
    httpAgent,
    httpsAgent,
});

const isAxiosCacheInstance = (instance: AxiosStatic | AxiosCacheInstance): instance is AxiosCacheInstance => {
    // @ts-ignore `.defaults.cache` only exists on AxiosCacheInstance
    return !!instance.defaults.cache;
};

async function createClient(): Promise<Client> {
    if (process.env.NEXT_RUNTIME === 'edge') {
        const xior = await import('xior').then((mod) => mod.Xior);
        const instance = xior.create();
        return instance as unknown as AxiosStatic;
    }

    const axios = (await import('axios')).default as AxiosStatic;
    if (isAxiosCacheInstance(axios)) {
        return axios;
    }

    if (typeof window === 'undefined') {
        const HttpAgent = await import('agentkeepalive').then((mod) => mod.default);

        const httpOptions: HttpsOptions = {
            keepAlive: true,
            scheduling: 'fifo',
            keepAliveMsecs: 1 * Duration.second,
            freeSocketTimeout: 5 * Duration.second,
            timeout: 10 * Duration.second,
        };

        httpAgent = new HttpAgent(httpOptions);
        httpsAgent = new HttpAgent.HttpsAgent(httpOptions);

        const instance = axios.create({
            httpAgent,
            httpsAgent,
            /**
             *  Internal network costs are cheaper than cpu,
             *  therefore we request without encoding
             */
            headers: {
                'accept-encoding': 'identity',
            },
            /**
             * request.ts isSuccess() will validate the status,
             * so to ensure 404 are being cached and other error codes
             * are being passed along to multiple simultanious requests
             * we only consider 401 and 403 as errors
             */
            validateStatus: (status) => status !== 401 && status !== 403,
        });

        const { setupCache, buildMemoryStorage } = await import('axios-cache-interceptor');
        const { registerInterceptor } = await import('axios-cached-dns-resolve').then(
            (mod) => mod.default || mod,
        );
        const cachedInstance = setupCache(instance as ImportAxiosInstance, {
            /**
             * cacheTakeover is made for browsers to prevent them handling the caching,
             * nodejs/axios doesn't cache anything by default,
             * so we disable it to get a 304 responses instead of a full body
             */
            cacheTakeover: false,
            storage: buildMemoryStorage(
                /**
                 * cloneData: if data should be structuredClone'ed each time gotten and written to cache
                 * however, we prefer to structuredClone manually when it makes sense
                 */
                false,
                /**
                 * cleanupInterval
                 * The interval in milliseconds to run a setInterval job of cleaning old entries
                 */
                5 * Duration.minute,
                /**
                 * maxEntries
                 * The maximum number of entries to keep in the storage, FIFO
                 */
                500,
            ),
            debug: (msg) => {
                logger.debug(msg);
            },
        });
        registerInterceptor(cachedInstance);
        return cachedInstance;
    } else {
        const instance = axios.create();
        return instance;
    }
}

const createClientOrWait = async (): Promise<Client> => {
    if (lock) {
        const promise = new Promise<Client>((resolve) => {
            awaitingClient.push(resolve);
        });
        return await promise;
    }
    lock = true;
    const client = await createClient();
    while (awaitingClient.length > 0) {
        const resolve = awaitingClient.pop(); // Release the reference
        if (resolve) resolve(client);
    }
    return client;
};

export const getClient = async (): Promise<AxiosInstance> => {
    if (!globalClient) {
        globalClient = await createClientOrWait();
    }
    return globalClient as AxiosInstance;
};
