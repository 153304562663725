/**
 * WARN: Only use this function on the client-side. Use the `isEnabled` constant on the server-side.
 * @deprecated It always returns `true`.
 */
export const getIsEnabled = (_env: Record<string, string | undefined>) => true;
// export const getIsEnabled = (env: Record<string, string | undefined>) =>
//     ['production', 'testing'].indexOf(env.APP_ENV || env.NODE_ENV || '') === -1 && env.NODE_ENV !== 'test';

/**
 * WARN: Only use this constant on the server-side. Use `getIsEnabled(contextData.env)` on the client-side.
 * @deprecated The value is always `true`.
 */
export const isEnabled = getIsEnabled(process.env);
