import { MissingKeyError } from './MissingKeyError';

export class ByKey<T> {
    constructor(private readonly byKey: { [key: string]: T }) {}

    /**
     * Returns the value for the given key. If no value is found, the fallback will be returned.
     * If no fallback is given, then a MissingKeyError is thrown.
     * @param key
     * @param fallback
     */
    get(key: string, fallback?: T): T {
        if (this.byKey[key] === undefined) {
            if (fallback !== undefined) {
                return fallback;
            }
            throw new MissingKeyError(key);
        }
        return this.byKey[key];
    }

    set(key: string, value: T): void {
        this.byKey[key] = value;
    }

    has(key: string): boolean {
        return this.byKey[key] !== undefined;
    }

    values(): T[] {
        return Object.values(this.byKey);
    }

    keys(): string[] {
        return Object.keys(this.byKey);
    }
}
