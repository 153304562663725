const axios = require('axios');
const limiter = require('limiter');

let rateLimiter = false;
const tryCreatingMissingKey = async (namespace, key) => {
    console.warn(`[i18n] "${namespace}:${key}" is missing. Trying to add "${key}" to "${namespace}".`);

    if (!rateLimiter) rateLimiter = new limiter.RateLimiter({ tokensPerInterval: 1, interval: 'second' });
    await rateLimiter.removeTokens(1);
    axios({
        method: 'post',
        url: process.env.NEXT_SERVER_API_HOST_LOCAL_VP + '/lokalise/key',
        data: {
            file: namespace,
            key: key,
        },
    })
        .then(function (response) {
            console.warn(`[i18n] result:`, response.data);
        })
        .catch(function (error) {
            console.error(`[i18n] error:`, error);
        });
};

exports.tryCreatingMissingKey = tryCreatingMissingKey;
