/**
 * Duration: default durations values for readable code
 * @enum
 */
export const Duration = {
    /**
     * Duration.millisecond: 1ms
     */
    millisecond: 1,
    /**
     * Duration.second: 1 * 1000 = 1000ms
     */
    second: 1000,
    /**
     * Duration.minute: 60 * 1000 = 60.000ms
     */
    minute: 60000,
    /**
     * Duration.fiveMinutes: 5 * 60 * 1000 = 300.000ms
     */
    fiveMinutes: 300000,
    /**
     * Duration.hour: 60 * 60 * 1000 = 3.600.000ms
     */
    hour: 3600000,
    /**
     * Duration.day: 24 * 60 * 60 * 1000 = 86.400.000ms
     */
    day: 86400000,
    /**
     * Duration.week: 7 * 24 * 60 * 60 * 1000 = 604.800.000ms
     */
    week: 604800000,
    /**
     * Duration.month (30 days): 30 * 24 * 60 * 60 * 1000 = 2.592.000.000ms
     */
    month: 2592000000,
    /**
     * Duration.year  (365 days): 365 * 24 * 60 * 60 * 1000 = 31.536.000.000ms
     */
    year: 31536000000,
};
