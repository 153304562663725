import { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

import { Icon, IconOption } from '@common/atoms/Icon/';
import { logger } from '@common/logger';

import styles from './FlashMessage.module.scss';

export type Type = 'success' | 'warning' | 'error';

export interface PublicProps {
    type?: Type;
    title?: string;
    message?: string;
    icon?: IconOption;
    isClosable?: boolean;
    delayClose?: number;
}

export interface Props {
    id: number;
    type?: Type;
    title: string;
    message?: string;
    icon?: IconOption;
    isClosable?: boolean;
    delayClose?: number;
    removeMessage: (id: number) => any;
}

export const FlashMessage: FC<Props> = ({
    id,
    type,
    title,
    message,
    icon,
    isClosable,
    delayClose,
    removeMessage,
}) => {
    const flashMessageRef = useRef<HTMLLIElement>(null);
    const classNames = ['FlashMessage', styles.FlashMessage];

    useEffect(() => {
        gsap.fromTo(flashMessageRef.current, { x: '-200' }, { x: '0' });

        if (delayClose) {
            setTimeout(() => {
                logger.debug('FlashMessage::timeout', id);
                removeMessage(id);
            }, delayClose);
        }
    });

    if (type === 'error') {
        classNames.push(styles.error);
        if (!icon) icon = IconOption.bell;
    } else if (type === 'warning') {
        classNames.push(styles.warning);
        if (!icon) icon = IconOption.bell;
    } else if (type === 'success') {
        classNames.push(styles.success);
        if (!icon) icon = IconOption.like;
    }
    if (!icon) icon = IconOption.cross;

    const ChosenIcon = Icon[IconOption[icon]];

    return (
        <li key={id} id={String(id)} className={classNames.join(' ')} ref={flashMessageRef}>
            {isClosable ? <Icon.cross onClick={() => removeMessage(id)} /> : null}
            {icon ? <ChosenIcon /> : null}
            <h1>{title}</h1>
            {message ? <p>{message}</p> : null}
        </li>
    );
};
