import { dot } from 'dot-object';
import newrelic from 'newrelic';

import { ApiError } from '@common/clients/api/core/ApiError';
import { logger } from '@common/logger';

type NotFoundError = ApiError & { body?: { message: string } };
export const isNotFoundError = (err: ApiError): err is NotFoundError => err.status === 404;
export const isApiError = (err: unknown): err is ApiError => err instanceof ApiError;

export class NotFound extends ApiError {
    constructor({ request, url, body, message }: NotFoundError) {
        super(
            request,
            { url, status: 404, statusText: 'Not Found', body, ok: false },
            `${message} - ${body?.message}`,
        );
        this.name = 'NotFoundError';
    }
}

const WARNING_STATUSES = new Set([300, 401, 403, 404]);

export const generateCatch = <T>(defaultValue: T) => {
    return (err: any): T => {
        const isServerSide = typeof window === 'undefined' || window.navigator.appVersion === 'undefined';

        if (isApiError(err)) {
            const attributes = dot(
                JSON.parse(JSON.stringify({ request: err.request, 'request.fullUrl': err.url })),
            );

            if (err?.status && WARNING_STATUSES.has(err.status)) {
                logger.warn(err, attributes);
            } else {
                logger.error(err, attributes);
            }

            if (isServerSide) {
                const expected = isNotFoundError(err);
                const error = isNotFoundError(err) ? new NotFound(err) : err;

                newrelic.noticeError(error, attributes, expected);
            }
        } else if (err instanceof Error) {
            logger.error(err);
            if (isServerSide) newrelic.noticeError(err);
        }

        return defaultValue;
    };
};

export const defaultCatch = generateCatch(undefined);
export const defaultArrayCatch = generateCatch([]);
