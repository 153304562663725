/**
 * Regexp for getting dynamic values from within a url for example
 * Keep in mind to not use .test() due to the /g flag
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec#description
 */
const dynamicValues = new RegExp(
    [`\\b[a-z0-9-]+[0-9-][a-z0-9-]+\\b`, `\\b\\d+\\b`, `\\b[dw-]{20,}\\b`, `\\?.+`].join('|'),
    'gim',
);

const dynamicTagSlug = new RegExp(`tag/[a-z0-9-]+\\b`, 'gim');

export const stripDynamicValues = (value: string) => {
    dynamicValues.lastIndex = 0;
    dynamicTagSlug.lastIndex = 0;
    return value ? value.replace(dynamicValues, '*').replace(dynamicTagSlug, 'tag/*') : value;
};
