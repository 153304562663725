import { ContextData } from '@common/defaults/ContextData';

import { SupertokensStatus } from './SupertokensStatus';
import { updateStatus } from './updateStatus';

export const getStatus = (contextData: Partial<ContextData>, isClientSide: boolean): SupertokensStatus => {
    if (!contextData.supertokens) {
        return SupertokensStatus.UNKNOWN;
    }
    updateStatus(contextData, isClientSide);
    return contextData.supertokens.status;
};
