export enum ItemsPerPage {
    /** 4 items per page */
    TINY = 4,

    /** 10 items per page */
    SMALL = 10,

    /** 15 items per page */
    STANDARD = 15,

    /** 25 items per page */
    BIG = 25,

    /** 50 items per page */
    HUGE = 50,

    /** 250 items per page */
    HUMONGOUS = 250,
}
