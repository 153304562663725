import { GetServerSideProps, GetServerSidePropsContext } from 'next';

import { ContextData } from '@common/defaults';

import { populateContextData } from './populateContextData';

interface Props {
    contextData: ContextData;
}

export const defaultGetServerSideProps: GetServerSideProps<Props> = async (
    context: GetServerSidePropsContext,
) => {
    const contextData = await populateContextData(context);
    return { props: { contextData } };
};
