/* NOTE: Code is based on code generated from https://github.com/ferdikoomen/openapi-typescript-codegen/blob/master/src/templates/core/axios/request.hbs but slightly modified */
import type { AxiosError, AxiosInstance, AxiosResponse, AxiosStatic } from 'axios';
import newrelic from 'newrelic';

import type { ApiRequestOptions } from '@common/clients/api/core/ApiRequestOptions';
import type { ApiResult } from '@common/clients/api/core/ApiResult';
import type { OnCancel } from '@common/clients/api/core/CancelablePromise';
import { CancelablePromise } from '@common/clients/api/core/CancelablePromise';
import type { OpenAPIConfig } from '@common/clients/api/core/OpenAPI';
import {
    catchErrorCodes,
    getFormData,
    getHeaders,
    getRequestBody,
    getResponseBody,
    getResponseHeader,
} from '@common/clients/api/core/request';
import { RequestConfig } from '@common/clients/request/RequestConfig';
import { logger } from '@common/logger';
import { stripDynamicValues } from '@common/utils/stripDynamicValues';

declare global {
    interface Window {
        __STORYBOOK_PREVIEW__: any;
    }
}

/**
 * ArrayQueryType
 * Not every api expects array-type query parameters to be handled the same way.
 * @enum
 */
export enum ArrayQueryType {
    /**
     * REPEAT_KEY (default)
     *
     * {"foo":[1,2]} becomes /?foo=1&foo=2
     */
    REPEAT_KEY,

    /**
     * REPEAT_KEY_WITH_BRACKETS
     *
     * {"foo":[1,2]} becomes /?foo[]=1&foo[]=2
     */
    REPEAT_KEY_WITH_BRACKETS,

    /**
     * COMMA_SEPARATED
     *
     * {"foo":[1,2]} becomes /?foo=1,2
     */
    COMMA_SEPARATED,
}

let axios: AxiosStatic;

/**
 * the ms added to the abort controller timeout,
 * since it's preferred to have axios timeout timing out
 */
const ADDITIONAL_CONNECTION_TIMEOUT = 100;

const isSuccess = (status: number): boolean => {
    // axios-cache-inceptor doesn't change the status code from 304 to 200
    return (status >= 200 && status < 300) || status === 304;
};

export const isDefined = <T>(value: T | null | undefined): value is Exclude<T, null | undefined> => {
    return value !== undefined && value !== null;
};

const getQueryString = (config: ExtendedOpenAPIConfig, params: Record<string, any>): string => {
    const qs: string[] = [];

    const append = (key: string, value: any) => {
        qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
    };

    const appendBracketedParam = (key: string, value: unknown) => {
        qs.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(String(value))}`);
    };

    const process = (key: string, value: any) => {
        if (isDefined(value)) {
            if (Array.isArray(value)) {
                if (config.ARRAY_QUERY_TYPE === ArrayQueryType.COMMA_SEPARATED) {
                    append(key, value.join(','));
                } else if (config.ARRAY_QUERY_TYPE === ArrayQueryType.REPEAT_KEY_WITH_BRACKETS) {
                    value.forEach((v) => {
                        appendBracketedParam(key, v);
                    });
                } else {
                    value.forEach((v) => {
                        process(key, v);
                    });
                }
            } else if (typeof value === 'object') {
                Object.entries(value).forEach(([k, v]) => {
                    process(`${key}[${k}]`, v);
                });
            } else {
                append(key, value);
            }
        }
    };

    Object.entries(params).forEach(([key, value]) => {
        process(key, value);
    });

    if (qs.length > 0) {
        return `?${qs.join('&')}`;
    }

    return '';
};

const getUrl = (config: ExtendedOpenAPIConfig, options: ApiRequestOptions): string => {
    const encoder = config.ENCODE_PATH || encodeURI;

    const path = options.url
        .replace('{api-version}', config.VERSION)
        .replace(/{(.*?)}/g, (substring: string, group: string) => {
            if (options.path?.hasOwnProperty(group)) {
                return encoder(String(options.path[group]));
            }
            return substring;
        });

    const url = `${config.BASE}${path}`;
    if (options.query) {
        return `${url}${getQueryString(config, options.query)}`;
    }
    return url;
};

let concurrentRequests = 0;

const sendRequest = async <T>(
    config: ExtendedOpenAPIConfig,
    options: ApiRequestOptions,
    url: string,
    body: any,
    formData: FormData | undefined,
    headers: Record<string, string>,
    onCancel: OnCancel,
    axiosClient?: AxiosInstance | Promise<AxiosInstance>,
    requestConfig: RequestConfig = {},
): Promise<AxiosResponse<T>> => {
    if (!axios) axios = (await import('axios')).default as AxiosStatic;
    const client = axiosClient ? await axiosClient : axios;
    const timeout = requestConfig.timeout || config.TIMEOUT;

    const abortController = new AbortController();
    requestConfig = {
        url,
        headers,
        data: body ?? formData,
        method: options.method,
        withCredentials: config.WITH_CREDENTIALS,
        timeout: timeout,
        signal: abortController.signal,
        ...requestConfig,
    };

    let timeoutID: NodeJS.Timeout | undefined;
    if (timeout) {
        timeoutID = setTimeout(() => {
            logger.error(`request.ts: timeout`, { url, method: options.method, timeout });
            abortController.abort();
        }, timeout + ADDITIONAL_CONNECTION_TIMEOUT);
    }

    onCancel(() => {
        if (timeoutID) clearTimeout(timeoutID);
        abortController.abort();
    });

    try {
        const result = await sendActualRequest<T>(client, requestConfig);
        if (timeoutID) clearTimeout(timeoutID);
        return result;
    } catch (error) {
        if (timeoutID) clearTimeout(timeoutID);
        const axiosError = error as AxiosError<T>;
        if (axiosError.response) {
            return axiosError.response;
        }
        throw error;
    }
};

/**
 * Send the actual request.
 *
 * On ECONNRESET we want to retry within the same timeout
 *
 * @param client
 * @param requestConfig
 * @param retryCount Keep track of the number of retries. This argument is for internal use only.
 */
const sendActualRequest = async <T>(
    client: AxiosInstance,
    requestConfig: RequestConfig,
    retryCount: number = 0,
): Promise<AxiosResponse<T>> => {
    const maxRetries = requestConfig.maxRetries ?? 2;
    try {
        return typeof newrelic.startSegment === 'function'
            ? await newrelic.startSegment(
                  `sendActualRequest ${stripDynamicValues(requestConfig.url || '')}`,
                  true,
                  async () => await client.request(requestConfig),
              )
            : await client.request(requestConfig);
    } catch (error) {
        // Try again if ECONNRESET
        if (typeof error === 'object' && error !== null && 'code' in error && error.code === 'ECONNRESET') {
            if (retryCount < maxRetries) {
                retryCount++;
                logger.warn(`ECONNRESET, retry=${retryCount}/${maxRetries}, trying again`, error);
                return await sendActualRequest(client, requestConfig, retryCount);
            } else {
                logger.warn(`ECONNRESET, maxRetries=${maxRetries}, no retry`);
            }
        }
        throw error;
    }
};

export type ExtendedOpenAPIConfig = OpenAPIConfig & {
    ARRAY_QUERY_TYPE?: ArrayQueryType;
    TIMEOUT?: number;
};

/**
 * Request method
 * @param config The OpenAPI configuration object
 * @param options The request options from the service
 * @param axiosClient
 * @param requestConfig
 * @returns CancelablePromise<T>
 * @throws ApiError
 */
export const request = <T>(
    config: ExtendedOpenAPIConfig,
    options: ApiRequestOptions,
    axiosClient?: AxiosInstance | Promise<AxiosInstance>,
    requestConfig?: RequestConfig,
): CancelablePromise<T> => {
    // Prevent API calls from storybook
    if (typeof window === 'object' && window?.__STORYBOOK_PREVIEW__) {
        return new CancelablePromise((_resolve, reject) => {
            logger.trace(
                'WARNING: Api calls do not work from storybook, therefore this promise will never resolve',
            );
            reject();
        });
    }

    concurrentRequests++;
    if (concurrentRequests > 25 || concurrentRequests < 0) {
        logger.warn(`request.ts: concurrentRequests > 25`, { concurrentRequests });
    }

    return new CancelablePromise(async (resolve, reject, onCancel) => {
        try {
            const url = getUrl(config, options);
            const formData = getFormData(options);
            const body = getRequestBody(options);
            const headers = await getHeaders(config, options);

            if (!onCancel.isCancelled) {
                const response = await sendRequest<T>(
                    config,
                    options,
                    url,
                    body,
                    formData,
                    headers,
                    onCancel,
                    axiosClient,
                    requestConfig,
                );

                onCancel(() => concurrentRequests--);

                const responseBody = getResponseBody(response);
                const responseHeader = getResponseHeader(response, options.responseHeader);

                const result: ApiResult = {
                    url,
                    ok: isSuccess(response.status),
                    status: response.status,
                    statusText: response.statusText,
                    body: responseHeader ?? responseBody,
                };

                catchErrorCodes(options, result);
                concurrentRequests--;
                resolve(result.body);
            } else {
                concurrentRequests--;
                logger.warn("Request cancelled before it's executed", { options });
            }
        } catch (error) {
            concurrentRequests--;
            reject(error);
        }
    });
};
