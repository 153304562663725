export class DateTimeDuration {
    years: number;
    months: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    totalDays: number;
    inFuture: boolean = false;

    constructor(date: Date) {
        let delta: number = (new Date().getTime() - date.getTime()) / 1000;

        if (delta < 0) {
            delta = -delta;
            this.inFuture = true;
        }

        this.totalDays = Math.floor(delta / (60 * 60 * 24));

        this.years = Math.floor(delta / 31556952);
        delta -= this.years * 31556952;

        this.months = Math.floor(delta / (60 * 60 * 24 * 30)) % 346;
        delta -= this.months * (60 * 60 * 24 * 30);

        this.days = Math.floor(delta / (60 * 60 * 24)) % 30;
        delta -= this.days * (60 * 60 * 24);

        this.hours = Math.floor(delta / (60 * 60)) % 24;
        delta -= this.hours * (60 * 60);

        this.minutes = Math.floor(delta / 60) % 60;
        delta -= this.minutes * 60;

        this.seconds = Math.round(delta % 60);
        delta -= this.seconds * 60;
    }
}
