export const allLogLevels = ['off', 'trace', 'debug', 'info', 'warn', 'error', 'fatal'] as const;
export type LogLevel = (typeof allLogLevels)[number];

const isLogLevelOptions: string[] = [...allLogLevels];
const isLogLevel = (x: string): x is LogLevel => isLogLevelOptions.includes(x);

const logLevelValue =
    process.env.LOG_LEVEL || process.env.NEXT_SERVER_LOG_LEVEL || process.env.NEXT_PUBLIC_LOG_LEVEL || 'warn';

if (!isLogLevel(logLevelValue)) {
    throw new Error(
        `Environment variable 'LOG_LEVEL' must be one of the following options: ${allLogLevels.join(', ')}`,
    );
}

export const logLevel = logLevelValue;

export const getLogLevelsFrom = (minimumLevel: LogLevel): LogLevel[] => {
    return allLogLevels.filter(
        (level) =>
            allLogLevels.indexOf(level) >= allLogLevels.indexOf(minimumLevel) &&
            allLogLevels.indexOf(level) >= allLogLevels.indexOf(logLevel),
    );
};

export const getLogLevelsUpTo = (maximumLevel: LogLevel): LogLevel[] => {
    return allLogLevels.filter(
        (level) =>
            allLogLevels.indexOf(level) <= allLogLevels.indexOf(maximumLevel) &&
            allLogLevels.indexOf(level) >= allLogLevels.indexOf(logLevel),
    );
};

export const defaultLogLevels = getLogLevelsFrom(logLevel);
