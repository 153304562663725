import type { AxiosRequestConfig } from 'axios';

import { OpenAPI } from '@common/clients/api';
import { ArrayQueryType, request as __request } from '@common/clients/request';
import { BaseHttpRequest, CancelablePromise } from '@common/clients/sportmonks-f1';
import { ApiRequestOptions } from '@common/clients/sportmonks-f1/core/ApiRequestOptions';
import { ContextData } from '@common/defaults/ContextData';

import { getClient } from './getClient';

const timeout = Number(process.env.NEXT_PUBLIC_SPORTMONKS_F1_TIMEOUT) || 5000;

export class SportmonksF1BaseRequest extends BaseHttpRequest {
    axiosOptions: AxiosRequestConfig | undefined;

    constructor(contextData: ContextData, isClientSide: boolean, axiosOptions?: AxiosRequestConfig) {
        super({
            ...OpenAPI,
            BASE:
                isClientSide || !contextData.config.sportmonksF1.internalHost
                    ? contextData.config.sportmonksF1.host
                    : contextData.config.sportmonksF1.internalHost,
            VERSION: '1.0.0',
            WITH_CREDENTIALS: false,
            CREDENTIALS: 'omit',
        });
        this.axiosOptions = axiosOptions;
    }

    public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
        const extendedConfig = {
            ...OpenAPI,
            ...this.config,
            TIMEOUT: timeout,
            ARRAY_QUERY_TYPE: ArrayQueryType.COMMA_SEPARATED,
        };
        return __request(
            extendedConfig,
            options,
            getClient(),
            this.axiosOptions,
        ) as unknown as CancelablePromise<T>;
    }
}
