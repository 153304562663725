import { Logger as TsEDLogger } from '@tsed/logger';
import { AsyncLocalStorage } from 'async_hooks';

import { LogLevel } from '../logLevel';

interface TimeLog {
    startTime: number;
    level: LogLevel;
}

interface AdditionalContext {
    [key: string | number]: number | string | boolean | undefined;
}

const asyncLocalStorage =
    typeof AsyncLocalStorage !== 'undefined' ? new AsyncLocalStorage<AdditionalContext>() : undefined;

export class Logger extends TsEDLogger {
    private additionalContext?: AdditionalContext;
    private timeLogs = new Map<string, TimeLog>();

    constructor(name?: string) {
        super(name);
    }

    addAdditionalContext(data: unknown[]): unknown[] {
        const additionalContext = this.getAdditionalContextStorage();
        if (additionalContext) data.push({ additionalContext });
        return data;
    }

    getAdditionalContextStorage(): AdditionalContext | undefined {
        if (!asyncLocalStorage) {
            return this.additionalContext;
        }
        return asyncLocalStorage.getStore();
    }

    registerAdditionalContext(data: AdditionalContext) {
        if (!asyncLocalStorage) {
            this.additionalContext = {
                ...(this.additionalContext || {}),
                ...data,
            };
        } else {
            const storage = this.getAdditionalContextStorage();
            if (!storage) {
                asyncLocalStorage.enterWith(data);
            } else {
                for (const key in data) {
                    storage[key] = data[key];
                }
            }
        }
    }

    override trace(...data: unknown[]) {
        return super.trace(...this.addAdditionalContext(data));
    }

    override debug(...data: unknown[]) {
        return super.debug(...this.addAdditionalContext(data));
    }

    override info(...data: unknown[]) {
        return super.info(...this.addAdditionalContext(data));
    }

    override warn(...data: unknown[]) {
        return super.warn(...this.addAdditionalContext(data));
    }

    override error(...data: unknown[]) {
        return super.error(...this.addAdditionalContext(data));
    }

    override fatal(...data: unknown[]) {
        return super.fatal(...this.addAdditionalContext(data));
    }

    time(label: string = 'default', level: LogLevel = 'info', startLogLevel: LogLevel = 'off'): Logger {
        if (this.timeLogs.has(label)) {
            this.warn(`Logger: "${label}" already exists.`);
            return this;
        }

        this.timeLogs.set(label, { startTime: performance.now(), level });
        if (startLogLevel !== 'off') this[startLogLevel](`Logger: "${label}" started.`);
        return this;
    }

    timeEnd(label: string = 'default'): Logger {
        const entry = this.timeLogs.get(label);
        if (entry === undefined) {
            this.warn(`Logger: "${label}" does not exist.`);
            return this;
        }

        const { startTime, level } = entry;
        const duration = (performance.now() - startTime).toFixed(3);
        if (level !== 'off') this[level](`${label}: ${duration}ms`);
        this.timeLogs.delete(label);
        return this;
    }
}
