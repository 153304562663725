import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { Interceptors } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { DriversService } from './services.gen';
import { LivescoresService } from './services.gen';
import { SeasonsService } from './services.gen';
import { StagesService } from './services.gen';
import { TeamsService } from './services.gen';
import { TracksService } from './services.gen';
import { WinnersService } from './services.gen';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class SportmonksF1ApiClient {

	public readonly drivers: DriversService;
	public readonly livescores: LivescoresService;
	public readonly seasons: SeasonsService;
	public readonly stages: StagesService;
	public readonly teams: TeamsService;
	public readonly tracks: TracksService;
	public readonly winners: WinnersService;

	public readonly request: BaseHttpRequest;

	constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
		this.request = new HttpRequest({
			BASE: config?.BASE ?? 'https://f1.sportmonks.com',
			VERSION: config?.VERSION ?? '1.0.0',
			WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
			CREDENTIALS: config?.CREDENTIALS ?? 'include',
			TOKEN: config?.TOKEN,
			USERNAME: config?.USERNAME,
			PASSWORD: config?.PASSWORD,
			HEADERS: config?.HEADERS,
			ENCODE_PATH: config?.ENCODE_PATH,
			interceptors: {
				request: config?.interceptors?.request ?? new Interceptors(),
				response: config?.interceptors?.response ?? new Interceptors(),
      },
		});

		this.drivers = new DriversService(this.request);
		this.livescores = new LivescoresService(this.request);
		this.seasons = new SeasonsService(this.request);
		this.stages = new StagesService(this.request);
		this.teams = new TeamsService(this.request);
		this.tracks = new TracksService(this.request);
		this.winners = new WinnersService(this.request);
	}
}
