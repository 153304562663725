/**
 * Enum for the number of items in smaller component
 * lists, e.g. the number of items in a Widget
 */
export enum ItemsPerComponent {
    /** 2 items per component */
    SMALL = 3,

    /** 5 items per component */
    STANDARD = 5,

    /** 15 items per component */
    HUGE = 15,
}
